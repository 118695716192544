import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import { getAllChampionsStats } from "@/game-lol/fetches/lol-fetch-champions-data.mjs";
import LolArenaStatsPatch from "@/game-lol/models/lol-arena-stats-patch.mjs";
import LoLChampionsArenaStats from "@/game-lol/models/lol-champions-arena.mjs";
import LoLChampionsArenaDuosStats from "@/game-lol/models/lol-champions-arena-duos.mjs";
import { updateLatestChampionsPatch } from "@/game-lol/utils/actions.mjs";
import { getKeyForArenaChampionsData } from "@/game-lol/utils/arena-queue-utils.mjs";

async function fetchData(params, searchParams) {
  const [tab] = params;

  switch (tab) {
    case "arena": {
      const { patch } = await getData(
        API.getArenaStatsPatch(),
        LolArenaStatsPatch,
        ["lol", "arenaStatsPatch"],
      );
      const key = getKeyForArenaChampionsData(patch);
      // const patchFull = getLatestPatchForStaticData()
      // const patch = prettyPatch(patchFull);
      await getData(API.getArenaChampionsStats(patch), LoLChampionsArenaStats, [
        "lol",
        "championStats",
        key,
      ]);
      break;
    }
    case "arena-duos": {
      const { patch } = await getData(
        API.getArenaStatsPatch(),
        LolArenaStatsPatch,
        ["lol", "arenaStatsPatch"],
      );
      const key = getKeyForArenaChampionsData(patch, true);
      // const patchFull = getLatestPatchForStaticData()
      // const patch = prettyPatch(patchFull);
      await getData(
        API.getArenaChampionsDuosStats(patch),
        LoLChampionsArenaDuosStats,
        ["lol", "championStats", key],
      );
      break;
    }
    case "aram":
      await getAllChampionsStats(searchParams, "aram").then((data) => {
        updateLatestChampionsPatch(data);
      });
      break;
    case "nexus-blitz":
      await getAllChampionsStats(searchParams, "nexus-blitz").then((data) => {
        updateLatestChampionsPatch(data);
      });
      break;
    case "urf":
      await getAllChampionsStats(searchParams, "urf").then((data) => {
        updateLatestChampionsPatch(data);
      });
      break;
    case "one-for-all":
      await getAllChampionsStats(searchParams, "one-for-all").then((data) => {
        updateLatestChampionsPatch(data);
      });
      break;
    case undefined:
    default:
      await getAllChampionsStats(searchParams).then((data) => {
        updateLatestChampionsPatch(data);
      });
      break;
  }
}

export default fetchData;
